<template>
  <div class="wrapper-detail">
    <admin-title-new :title="$route.meta.name"></admin-title-new>
    <el-tabs v-model="activeName" class="store-tab">
      <el-tab-pane label="基础信息" name="1"></el-tab-pane>
      <el-tab-pane label="机审信息" name="2"></el-tab-pane>
      <el-tab-pane label="三方数据" name="3"></el-tab-pane>
    </el-tabs>

    <!-- 基础信息 -->
    <DetailBase
      ref="base"
      v-show="activeName == 1"
      @updateDetail="getReviewOrderDetail"
      @goJS="goJS"
    />

    <!-- 机审信息 -->
    <DetailJiShen ref="jishen" v-show="activeName == 2" />

    <!-- 三方数据 -->
    <DetailRisk v-show="activeName == 3" />

    <div style="height: 60px"></div>

    <!-- 审核结果 -->
    <div class="result">
      <AuditResult
        @result="handleResult"
        :detailInfo="detailInfo"
        :orderScoreProfile="detailInfo.orderScoreProfile || {}"
      ></AuditResult>
    </div>
    <el-dialog title="" :visible.sync="dialogVisible" width="50%">
      <!-- 同意 -->
      <div v-show="result == 1" class="content">
        <div class="item">
          <span>
            <span class="my-waring">审核通过</span>
          </span>
        </div>
        <div class="item">
          <span>请确认账期：</span>
          <span>
            <el-checkbox-group v-model="periodList">
              <el-checkbox v-for="item in periodList" :key="item" :label="item"
                >第{{ item }}期</el-checkbox
              >
            </el-checkbox-group>
          </span>
        </div>
        <div
          class="item"
          v-if="extraServiceList && extraServiceList.length > 0"
        >
          <span>增值服务：</span>
          <span>
            <el-checkbox-group v-model="extraServiceCodeList">
              <el-checkbox
                v-for="(item, i) in extraServiceList"
                :key="i"
                :label="item.code"
              >
                {{ item.name }}（{{ item.price }}元）
              </el-checkbox>
            </el-checkbox-group>
          </span>
        </div>
        <div class="item">
          <span>选择合同：</span>
          <span>
            <el-radio-group v-model="contractId">
              <el-radio
                v-for="item in contactList"
                :key="item.id"
                :label="item.id"
                :disabled="item.id === 8 && !enableNotaryContract"
                >{{ item.contractName }}</el-radio
              >
            </el-radio-group>
          </span>
        </div>
        <div class="item">
          <span>备注：</span>
          <span>
            <el-input
              v-model="reviewPass"
              rows="5"
              type="textarea"
              placeholder="请输入备注"
            ></el-input>
          </span>
        </div>
        <div class="item" v-if="tip">
          <span></span>
          <span class="tip"
            ><i class="el-icon-warning"></i> 该用户与<span
              style="font-weight: 700; color: #000"
              >{{ tip }}</span
            >库中数据匹配，请复核后谨慎操作！</span
          >
        </div>
      </div>
      <!-- 拒绝 -->
      <div v-show="result == 2" class="content">
        <div class="tips">
          审核 <span class="my-waring">拒绝</span> ，请输拒绝入原因
        </div>
        <el-input
          v-model="content"
          rows="5"
          type="textarea"
          placeholder=""
        ></el-input>
        <div class="tip-hit" v-if="tip">
          <span class="tip"
            ><i class="el-icon-warning"></i> 该用户与<span
              style="font-weight: 700; color: #000"
              >{{ tip }}</span
            >库中数据匹配，请复核后谨慎操作！</span
          >
        </div>
      </div>
      <!-- 取消 -->
      <div v-show="result == 3" class="content">
        <div class="tips">
          <span class="my-waring">取消</span>订单，请输入取消原因
        </div>
        <el-input
          v-model="remark"
          rows="5"
          type="textarea"
          placeholder=""
        ></el-input>
        <div class="tip-hit" v-if="tip">
          <span class="tip"
            ><i class="el-icon-warning"></i> 该用户与<span
              style="font-weight: 700; color: #000"
              >{{ tip }}</span
            >库中数据匹配，请复核后谨慎操作！</span
          >
        </div>
      </div>

      <div slot="footer">
        <el-button @click="dialogVisible = false" size="small">关 闭</el-button>
        <el-button type="primary" @click="save" size="small">提 交</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import {
  handleReviewPassAPI,
  handleReviewCancelAPI,
  handleReviewRefuseAPI,
  getContactListAPI,
} from "./modules/api";
import DetailBase from "./detail-base.vue";
import DetailJiShen from "./detail-jishen.vue";
import DetailRisk from "./detail-risk.vue";
import AuditResult from "./modules/review-result.vue";

export default {
  name: "Detail",
  components: {
    DetailBase,
    DetailJiShen,
    DetailRisk,
    AuditResult,
  },
  data() {
    return {
      dialogVisible: false,
      activeName: "1",
      detailInfo: {},
      result: "",
      content: "",
      remark: "",
      contractId: "",
      contactList: [],
      reviewPass: "",
      tip: "",
      extraServiceList: [],
      extraServiceCodeList: [],
      storeBillIndex: 0,
    };
  },
  watch: {
    activeName: {
      handler(newV, oldV) {
        if (this.activeName == 2) {
          this.$refs.jishen.getRiskReview();
        }
      },
    },
  },
  computed: {
    ...mapState({
      rate: (state) => state.audit.rate,
      periodList: (state) => state.audit.periodList,
    }),
    enableNotaryContract() {
      if (this.detailInfo.storeBillOptionList) {
        const billList = this.detailInfo.storeBillOptionList[
          this.storeBillIndex
        ].billList.filter((bill, index) => {
          return this.periodList.includes(bill.billNum);
        });

        return billList.every((bill) => {
          return parseFloat(bill.billPrice) >= 600;
        });
      } else {
        return parseFloat(this.detailInfo.eachRentPrice) >= 600;
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    if (to.name == "ReviewUpdateGoods") {
      this.$refs.base.flag = false;
      this.$refs.base.updatePeriod = false;
    }
    next();
  },
  mounted() {},

  methods: {
    goJS() {
      this.activeName = "2";
    },
    async getReviewOrderDetail(obj) {
      this.detailInfo = obj;
      this.extraServiceList = obj.extraServiceList;
      this.extraServiceCodeList = obj.extraServiceCodeList;
    },
    handleResult(val) {
      const storeBillIndex = Number(
        localStorage.getItem("storeBillIndex:" + this.detailInfo.orderNo) ??
          "0",
      );

      this.hit();
      this.result = val;
      this.storeBillIndex = storeBillIndex;
      this.dialogVisible = true;
      if (this.result == 1) {
        this.getContactList();
        this.contractId = "";
      }
    },
    // 撞库
    async hit() {
      let arr = [];
      const { rosterLevelList } = this.detailInfo;
      rosterLevelList.forEach((item) => {
        if (item == 2) {
          arr.push("黑名单");
        } else if (item == 3) {
          arr.push("灰名单");
        } else if (item == 1) {
          arr.push("白名单");
        }
      });
      this.tip = arr.join("、");
    },
    save() {
      this.dialogVisible = false;
      if (this.result == 1) {
        if (!this.contractId) {
          this.$alert("请选择合同!", "提示", {
            confirmButtonText: "确定",
            type: "warning",
          });
          this.dialogVisible = true;
          return;
        }
        if (!this.reviewPass) {
          this.$alert("请填写备注!", "提示", {
            confirmButtonText: "确定",
            type: "warning",
          });
          this.dialogVisible = true;
          return;
        }
        let data = {
          orderNo: this.$route.params.id,
          prepayPeriodList: this.periodList,
          contractId: this.contractId,
          remark: this.reviewPass,
          storeComboOption: null,
          fixFirstBillPrice: null,
          extraServiceCodeList: this.extraServiceCodeList,
        };
        let updateStoreBillOptionList = JSON.parse(
          localStorage.getItem("updateBill:" + this.detailInfo.orderNo),
        );
        if (updateStoreBillOptionList) {
          let updateStoreBillOption =
            updateStoreBillOptionList[
              localStorage.getItem(
                "storeBillIndex:" + this.detailInfo.orderNo,
              ) - 0
            ];
          data.storeComboOption = updateStoreBillOption.optionType;
          data.fixFirstBillPrice = updateStoreBillOption.billList[0].billPrice;
        }
        handleReviewPassAPI(data).then(() => {
          this.$message.success("审核同意成功，即将关闭本页面...");
          setTimeout(() => {
            this.emitAwindow();
            window.close();
          }, 2000);
        });
      } else if (this.result == 2) {
        let data = {
          orderNo: this.$route.params.id,
          remark: this.content,
        };
        handleReviewRefuseAPI(data).then(() => {
          this.$message.success("拒绝成功，即将关闭本页面...");
          setTimeout(() => {
            this.emitAwindow();
            window.close();
          }, 2000);
        });
      } else if (this.result == 3) {
        let data = {
          orderNo: this.$route.params.id,
          remark: this.remark,
        };
        handleReviewCancelAPI(data).then(() => {
          this.$message.success("订单取消成功，即将关闭本页面...");
          setTimeout(() => {
            this.emitAwindow();
            window.close();
          }, 2000);
        });
      }
    },
    emitAwindow() {
      //window.opener 获取父页面的window元素
      //判断A窗口有没有window.opener和getBpageList是不是个方法
      if (window.opener && window.opener.getBpageList) {
        console.log(1, window.opener);
        window.opener.getBpageList();
      } else {
        //window.opener.frames[0] 获取到的window对象
        window.opener.frames[0].getBpageList();
      }
    },
    closePage() {
      this.emitAwindow();
      window.close();
    },
    async getContactList() {
      const res = await getContactListAPI({ orderNo: this.$route.params.id });
      this.contactList = (res || []).map((contact) => {
        // 非凤凰公证
        if (contact.id !== 8) {
          return contact;
        }
        const disabled = this.periodList.every(() => {});
        return {
          ...contact,
          disabled,
        };
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper-detail /deep/ {
  width: 100%;
  .title {
    font-weight: 600;
    margin: 10px 0;
    &:first-child {
      margin-top: 0;
    }
  }
  .store-tab {
    .el-tabs__nav {
      height: 48px;
    }
    .el-tabs__header {
      margin-bottom: 0;
      .el-tabs__nav-wrap::after {
        display: none;
      }
      .el-tabs__item {
        font-size: 20px;
        color: #666666;
      }
      .el-tabs__item.is-active {
        color: #222222;
      }
      .el-tabs__active-bar {
        width: 43px !important;
        height: 4px;
        border-radius: 39px;
        left: 20px;
      }
    }
  }
  .detail-title {
    font-weight: 600;
    font-size: 14px;
    margin: 10px 0;
  }
  .result {
    position: fixed;
    left: 200px;
    bottom: 0;
    height: 80px;
    width: calc(100% - 200px);
    background-color: #fff;
    z-index: 2999;
    padding: 0 50px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  }
  .content {
    margin-top: 20px;
    .item {
      display: flex;
      margin: 15px 0;
      & > span {
        &:first-child {
          white-space: nowrap;
          margin-right: 10px;
        }
      }
      &:nth-child(n + 2) {
        & > span {
          &:first-child {
            width: 80px;
            text-align: right;
          }
          &:last-child {
            flex: 1;
          }
        }
      }
      .tip {
        background-color: #f9d7d9;
        padding: 5px;
        border-radius: 2px;
        font-weight: 600;
        // color: #101010;
        i {
          color: #e34d59;
          font-size: 16px;
          vertical-align: baseline;
        }
      }
    }
    .tips {
      margin: 5px 0;
      // font-weight: 600;
    }
  }
  .tip-hit {
    margin-top: 20px;
    background-color: #f9d7d9;
    padding: 5px;
    border-radius: 2px;
    font-weight: 600;
    // color: #101010;
    i {
      color: #e34d59;
      font-size: 16px;
      vertical-align: baseline;
    }
  }
  .el-dialog__body {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .el-dialog__header {
    padding-top: 10px;
  }
  .el-dialog__footer {
    padding: 20px;
  }
  .my-waring {
    color: #f01616;
    font-weight: 600;
    font-size: 18px;
  }
}
.without-layout {
  .wrapper-detail {
    height: 100% !important;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
  }
  .el-tabs {
    padding: 0 14% !important;
  }
}
</style>
