<template>
  <div @click="onClick">
    <el-card shadow="hover" class="card">
      <div
        :style="{
          border: `1px solid ${CmoboGradeToColorMap.get(value.comboGrade)}`,
        }"
        class="combo-card"
      >
        <div
          class="combo-title"
          :style="{
            backgroundColor: CmoboGradeToColorMap.get(value.comboGrade),
          }"
        >
          <el-radio class="radio" :value="checked" :label="true" />
          <div>方案{{ value.index }}</div>
          <div>首付 {{ value.prepayRate }} %</div>
          <div>(服务费{{ value.netRate }} %)</div>
        </div>
        <div class="combo-item">
          <div>零售价</div>
          <div>￥{{ value.salePrice }}</div>
        </div>
        <div class="combo-row">
          <div class="combo-item">
            <div>首付租金</div>
            <div class="large-combo-value">￥{{ value.prepayRentPrice }}</div>
          </div>
          <div class="combo-item">
            <div>监管锁</div>
            <div class="bold-combo-value">￥{{ value.lockPrice }}</div>
          </div>
        </div>
        <div class="combo-row">
          <div class="combo-item">
            <div>每期租金</div>
            <div class="large-combo-value">
              ￥{{ (value.leftRentPrice / value.leftBillNum).toFixed(2) }}
            </div>
          </div>
          <div class="combo-item">
            <div>剩余期数</div>
            <div class="bold-combo-value">{{ value.leftBillNum }}期</div>
          </div>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import { CmoboGradeToColorMap } from "@/enum/dict/combo";
export default {
  name: "ComboCard",
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    checked: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      CmoboGradeToColorMap,
    };
  },
  methods: {
    onClick() {
      this.$emit("checkedChange");
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  /deep/ .el-card__body {
    padding: 0;
  }
  cursor: pointer;
}

.combo-card {
  border-radius: 6px;
  overflow: hidden;
}

.combo-title {
  display: flex;
  height: 30px;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  gap: 6px;
}

.combo-row {
  width: 100%;
  display: flex;
}

.combo-item {
  display: flex;
  flex: 1;
  height: 34px;
  border-top: 1px solid #ddd;
  font-size: 13px;

  &:not(:last-child) {
    border-right: 1px solid #ddd;
  }

  & > div {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #ddd;
  gap: 8px;
  padding: 8px 0;

  .combo-name {
    max-width: 220px;
  }
}

.radio {
  margin-right: 0;
  /deep/ {
    .el-radio__label {
      display: none;
    }
  }
}

.large-combo-value {
  font-size: 16px;
  font-weight: bold;
}

.bold-combo-value {
  font-weight: bold;
}
</style>
