<template>
  <div class="wrapper-detail">
    <!-- 基础信息 -->
    <MemberInfo
      show_type="base"
      :detailInfo="detailInfo"
      :flag.sync="flag"
      @updateDetail="getReviewOrderDetail"
    />

    <!-- 客户评级 -->
    <template v-if="!!detailInfo.store">
      <LevelProgress
        :scoreProfileData="scoreProfileData"
        :finalLevel="finalLevel"
        :sysScore="sysScore"
        :sysLevel="sysLevel"
      />
    </template>

    <!-- 订单信息 -->
    <OrderInfo
      :detailInfo="detailInfo"
      :flag.sync="flag"
      @updateDetail="getReviewOrderDetail"
    />

    <!-- 账期 -->
    <template v-if="!!detailInfo.store">
      <PeriodStoreInfo
        ref="period"
        @resetBill="resetBill"
        :detailInfo="detailInfo"
      />
    </template>
    <template v-else>
      <PeriodInfo
        ref="period"
        :billPeriodNum="detailInfo.billPeriodNum"
        :eachRentPrice="detailInfo.eachRentPrice"
      />
    </template>

    <!-- 门店信息 -->
    <template v-if="!!detailInfo.store">
      <StoreInfo
        :detailInfo="detailInfo"
        :flag.sync="flag"
        @updateDetail="getReviewOrderDetail"
      />
    </template>

    <!-- 紧急联系人 -->
    <ContactInfo
      :type="1"
      :flag.sync="flag"
      :member="detailInfo.member"
      :orderInfo="detailInfo"
      @updateDetail="getReviewOrderDetail"
    />

    <!-- 订单备注 -->
    <ContactInfo
      :type="2"
      :flag.sync="flag"
      :member="detailInfo.member"
      :orderInfo="detailInfo"
      @updateDetail="getReviewOrderDetail"
    />

    <!-- 收货信息 -->
    <AddressInfo
      :flag.sync="flag"
      :orderAddress="detailInfo.orderAddress"
      @updateDetail="getReviewOrderDetail"
    />
  </div>
</template>

<script>
import { getReviewOrderDetailAPI } from "./api";
import MemberInfo from "./modules/member-info.vue";
import OrderInfo from "./modules/order-info.vue";
import StoreInfo from "./modules/store-info.vue";
import ContactInfo from "./modules/contact-info.vue";
import AddressInfo from "./modules/address-info.vue";
import PeriodInfo from "./modules/period-info.vue";
import PeriodStoreInfo from "./modules/period-store-info.vue";

export default {
  name: "DetailBase",
  components: {
    MemberInfo,
    OrderInfo,
    StoreInfo,
    ContactInfo,
    AddressInfo,
    PeriodInfo,
    PeriodStoreInfo,
  },
  data() {
    return {
      detailInfo: {},
      flag: true,
    };
  },
  computed: {
    finalLevel() {
      return this.detailInfo.orderScoreProfile
        ? this.detailInfo.orderScoreProfile.finalHitGrade
        : "";
    },
    sysScore() {
      return this.detailInfo.orderScoreProfile
        ? this.detailInfo.orderScoreProfile.sysHitScore
        : "";
    },
    sysLevel() {
      return this.detailInfo.orderScoreProfile
        ? this.detailInfo.orderScoreProfile.sysHitGrade
        : "";
    },
    scoreProfileData() {
      return this.detailInfo.orderScoreProfile || {};
    },
  },
  created() {
    // 可以更新账期
    localStorage.setItem("is_update_period", "true");
  },
  mounted() {
    // 窗口关闭清除本地缓存
    let that = this;
    window.onbeforeunload = () => {
      localStorage.removeItem("updateBill:" + that.detailInfo.orderNo);
      localStorage.removeItem("storeBillIndex:" + that.detailInfo.orderNo);
      localStorage.removeItem("storeBill:" + that.detailInfo.orderNo);
    };
  },
  activated() {
    this.getReviewOrderDetail();
  },
  deactivated() {
    // 页面失活清除本地缓存
    localStorage.removeItem("updateBill:" + this.detailInfo.orderNo);
    localStorage.removeItem("storeBillIndex:" + this.detailInfo.orderNo);
    localStorage.removeItem("storeBill:" + this.detailInfo.orderNo);
  },

  methods: {
    updateDetail() {},
    goJiShen() {
      this.$emit("goJS");
    },
    async getReviewOrderDetail() {
      this.detailInfo = await getReviewOrderDetailAPI(this.$route.params.id);
      if (localStorage.getItem("is_update_period") == "true") {
        if (this.detailInfo.storeBillOptionList) {
          let storeBillOption = JSON.stringify(
            this.detailInfo.storeBillOptionList,
          );
          localStorage.setItem(
            "storeBill:" + this.detailInfo.orderNo,
            storeBillOption,
          );
          localStorage.setItem(
            "updateBill:" + this.detailInfo.orderNo,
            storeBillOption,
          );
          this.$store.commit(
            "UPDATE_PERIOD",
            this.detailInfo.storeBillOptionList[
              localStorage.getItem(
                "storeBillIndex:" + this.detailInfo.orderNo,
              ) - 0
            ].prepayBill,
          );
        } else {
          this.$refs.period.periodList = this.detailInfo.prepayPeriodList;
          this.$store.commit("UPDATE_PERIOD", this.detailInfo.prepayPeriodList);
        }
      } else {
        if (this.detailInfo.storeBillOptionList) {
          Object.assign(
            this.detailInfo.storeBillOptionList,
            JSON.parse(
              localStorage.getItem("updateBill:" + this.detailInfo.orderNo),
            ),
          );
        }
      }
      // 设置账期不会随其他设置而改变
      localStorage.setItem("is_update_period", "false");
      this.$emit("updateDetail", this.detailInfo);
    },
    resetBill({ bill, index }) {
      // 修改当前账单
      Object.assign(this.detailInfo.storeBillOptionList[index], bill);
      // 备份当前修改后的账单
      localStorage.setItem(
        "updateBill:" + this.detailInfo.orderNo,
        JSON.stringify(this.detailInfo.storeBillOptionList),
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper-detail {
  width: 100%;
  flex: 1;
  margin-top: 15px;
  overflow-y: scroll;
  // overflow-x: hidden;
  .detail-title {
    font-weight: 600;
    font-size: 14px;
    margin: 20px 0 10px 0;
  }
  .arbitrage {
    font-size: 12px;
    color: #f56c6c;
  }
  .result {
    position: sticky;
    bottom: -28px;
    left: 240px;
    right: 0;
    background-color: #fff;
    z-index: 3999;

    .title {
      font-weight: 600;
      font-size: 14px;
      margin-bottom: 10px;
      // margin-top: 20px;
    }
  }
}
.without-layout .wrapper-detail {
  padding: 0 14% !important;
}
</style>
