<template>
  <div :class="['wrapper', orderType ? 'space-between' : '']">
    <div v-if="orderType" class="score">
      <div
        class="item"
        v-if="orderScoreProfile.sysHitGrade"
        :style="{
          backgroundColor: colorList.find(
            (i) => i.level == orderScoreProfile.sysHitGrade,
          )
            ? colorList.find((i) => i.level == orderScoreProfile.sysHitGrade)
                .color
            : '',
        }"
      >
        系统评级
        <span class="value">{{ orderScoreProfile.sysHitGrade }}</span>
        级
      </div>
      <div
        class="item"
        v-if="orderScoreProfile.finalHitGrade"
        :style="{
          backgroundColor: colorList.find(
            (i) => i.level == orderScoreProfile.finalHitGrade,
          )
            ? colorList.find((i) => i.level == orderScoreProfile.finalHitGrade)
                .color
            : '',
        }"
      >
        最终评级
        <span class="value">{{ orderScoreProfile.finalHitGrade }}</span>
        级
      </div>

      <div class="item arbitrage-price">
        <div>套现空间</div>
        ￥{{ detailInfo.arbitragePrice }}
      </div>
    </div>

    <div class="submit-btn">
      <el-button type="" class="btn" size="middle" @click="submitResult(3)"
        >取消</el-button
      >
      <el-button type="" class="btn" size="middle" @click="submitResult(2)"
        >拒绝</el-button
      >
      <el-button type="primary" size="middle" @click="submitResult(1)"
        >同意</el-button
      >
    </div>
    <el-dialog title="" :visible.sync="dialogVisible" width="50%">
      <!-- 同意 -->
      <div v-show="result == 1" class="content">
        <div class="item">
          <span>
            <span class="my-waring">审核通过</span>
          </span>
          <!-- <span class="my-waring">{{ memberGradeLog.gradeName }}</span> -->
        </div>
        <div class="item">
          <span> 请确认账期：</span>
          <span>
            <el-checkbox-group v-model="periodList">
              <el-checkbox v-for="item in periodList" :key="item" :label="item"
                >第{{ item }}期</el-checkbox
              >
            </el-checkbox-group>
          </span>
        </div>
        <div class="item">
          <span>选择合同：</span>
          <span>
            <el-radio-group v-model="contractId">
              <el-radio
                v-for="item in contactList"
                :key="item.id"
                :label="item.id"
                >{{ item.contractName }}</el-radio
              >
            </el-radio-group>
          </span>
        </div>
        <div class="item">
          <span>备注：</span>
          <span>
            <el-input
              v-model="reviewPass"
              rows="5"
              type="textarea"
              placeholder="请输入备注"
            ></el-input>
          </span>
        </div>
      </div>
      <!-- 拒绝 -->
      <div v-show="result == 2" class="content">
        <div class="tips">
          审核 <span class="my-waring">拒绝</span> ，请输拒绝入原因
        </div>
        <el-input
          v-model="content"
          rows="5"
          type="textarea"
          placeholder=""
        ></el-input>
      </div>
      <!-- 取消 -->
      <div v-show="result == 3" class="content">
        <div class="tips">
          <span class="my-waring">取消</span>订单，请输入取消原因
        </div>
        <el-input
          v-model="remark"
          rows="5"
          type="textarea"
          placeholder=""
        ></el-input>
      </div>
      <div slot="footer">
        <el-button @click="dialogVisible = false" size="small">关 闭</el-button>
        <el-button type="primary" @click="save" size="small">提 交</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import {
  handleReviewPassAPI,
  handleReviewCancelAPI,
  handleReviewRefuseAPI,
  getContactListAPI,
  hitAPI,
} from "./api";
export default {
  name: "AuditResult",
  props: {
    orderScoreProfile: {
      type: Object,
      default: () => {},
    },
    detailInfo: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      dialogVisible: false,
      result: null,
      content: "",
      remark: "",
      contractId: "",
      contactList: [],
      reviewPass: "",
      colorList: [
        {
          color: "#28D576",
          level: "A",
        },
        {
          color: "#1483EA",
          level: "B",
        },
        {
          color: "#FFAC46",
          level: "C",
        },
        {
          color: "#D05252",
          level: "D",
        },
      ],
    };
  },
  computed: {
    ...mapState({
      rate: (state) => state.audit.rate,
      periodList: (state) => state.audit.periodList,
    }),
    orderType() {
      let orderType = this.$route.query.orderType;
      return orderType == 2 || orderType == 4;
    },
  },
  mounted() {},

  methods: {
    submitResult(val) {
      let orderType = this.$route.query.orderType;
      if (
        (orderType == 2 || orderType == 4) &&
        !this.orderScoreProfile.finalHitGrade &&
        val == 1
      ) {
        this.$alert("用户评级不能为空", "提示", {
          confirmButtonText: "确定",
          type: "warning",
        });
        return;
      }
      this.$emit("result", val);
    },
    save() {
      this.dialogVisible = false;
      if (this.result == 1) {
        if (!this.contractId) {
          this.$alert("请选择合同!", "提示", {
            confirmButtonText: "确定",
            type: "warning",
          });
          this.dialogVisible = true;
          return;
        }
        if (!this.reviewPass) {
          this.$alert("请填写备注!", "提示", {
            confirmButtonText: "确定",
            type: "warning",
          });
          this.dialogVisible = true;
          return;
        }
        let data = {
          orderNo: this.$route.params.id,
          prepayPeriodList: this.periodList,
          contractId: this.contractId,
          remark: this.reviewPass,
        };
        handleReviewPassAPI(data).then(() => {
          this.$message.success("审核同意成功，即将关闭本页面...");
          setTimeout(() => {
            this.emitAwindow();
            window.close();
          }, 2000);
        });
      } else if (this.result == 2) {
        let data = {
          orderNo: this.$route.params.id,
          remark: this.content,
        };
        handleReviewRefuseAPI(data).then(() => {
          this.$message.success("拒绝成功，即将关闭本页面...");
          setTimeout(() => {
            this.emitAwindow();
            window.close();
          }, 2000);
        });
      } else if (this.result == 3) {
        let data = {
          orderNo: this.$route.params.id,
          remark: this.remark,
        };
        handleReviewCancelAPI(data).then(() => {
          this.$message.success("订单取消成功，即将关闭本页面...");
          setTimeout(() => {
            this.emitAwindow();
            window.close();
          }, 2000);
        });
      }
    },
    emitAwindow() {
      if (window.opener && window.opener.getBpageList) {
        console.log(1, window.opener);
        window.opener.getBpageList();
      } else {
        //window.opener.frames[0] 获取到的window对象
        window.opener.frames[0].getBpageList();
      }
    },
    closePage() {
      this.emitAwindow();
      window.close();
    },
    async getContactList() {
      const res = await getContactListAPI();
      this.contactList = res || [];
    },
    beforeClose() {},
  },
};
</script>

<style lang="scss" scoped>
.wrapper /deep/ {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .score {
    display: flex;
    align-items: center;
    .item {
      color: #fff;
      border-radius: 4px;
      margin-right: 20px;
      font-size: 14px;
      padding: 5px 10px;

      .value {
        font-size: 28px;
        font-weight: 550;
        margin: 0 5px;
      }
    }
  }
  .content {
    margin-top: 20px;
    .item {
      display: flex;
      margin: 15px 0;
      & > span {
        &:first-child {
          white-space: nowrap;
          margin-right: 10px;
        }
      }
      &:nth-child(n + 2) {
        & > span {
          &:first-child {
            width: 80px;
            text-align: right;
          }
          &:last-child {
            flex: 1;
          }
        }
      }
    }
    .tips {
      margin: 5px 0;
      // font-weight: 600;
    }
  }
  .submit-btn {
    text-align: right;
    .btn {
      background: #f2f3f5;
      color: #666666;
      border: none;
    }
    .el-button {
      border: none;
    }
  }
  .el-dialog__body {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .el-dialog__header {
    padding-top: 0;
  }
  .el-dialog__footer {
    padding: 40px 20px;
  }
  .my-waring {
    color: #f01616;
    font-weight: 600;
    font-size: 18px;
  }
  .tit {
    margin: 10px 0;
  }
}
.space-between {
  justify-content: space-between;
}
.arbitrage-price {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 52px;
  min-width: 130px;
  padding: 5px 10px;
  background-color: #d05252;
}
</style>
