<template>
  <el-dialog
    :visible="visible"
    title="方案修改"
    class="combo-dialog"
    @opened="afterOpen"
    @close="onClose"
    width="1300px"
    top="5vh"
  >
    <el-tabs v-model="searchData.comboType">
      <el-tab-pane
        v-for="status in tabs"
        :label="status.name"
        :name="status.value.toString()"
        :key="status.value"
      />
    </el-tabs>
    <div
      v-if="
        searchData.comboType === dict.EnumComboType.COMMON.toString() &&
        rewardComboNum > 0
      "
      class="search-form"
    >
      线路选择：
      <el-select v-model="searchData.comboRoute" size="small">
        <el-option
          v-for="item in dict.ComboRouteList"
          :label="item.name"
          :value="item.value"
          :key="item.value"
        />
      </el-select>
    </div>
    <div class="dialog-save">
      <el-button size="small" type="primary" @click="onSubmit">保 存</el-button>
    </div>
    <div class="combo-body">
      <div class="grade-list">
        <div
          v-for="(item, index) in groupedList"
          :key="item.grade.value"
          class="grade-group"
        >
          <div class="grade-name">{{ item.grade.name }}</div>
          <div class="combo-list">
            <div class="divider" v-if="index > 0" />
            <combo-card
              v-for="cmobo in item.list"
              :value="cmobo"
              :checked="form.comboId === cmobo.comboId"
              @checkedChange="onCheckedChange(cmobo.comboId)"
            />
          </div>
        </div>
      </div>
      <el-form
        ref="form"
        :model="form"
        label-width="50px"
        inline
        v-if="searchData.comboType == dict.EnumComboType.CUSTOM"
      >
        <el-form-item label="备注" style="margin-top: 30px">
          <el-input v-model="form.remark" placeholder="请输入" />
        </el-form-item>
      </el-form>
    </div>
  </el-dialog>
</template>

<script>
import {
  ComboGradeList,
  EnumComboType,
  CmoboGradeToColorMap,
  ComboTypeForReviewList,
  EnumComboRoute,
  ComboRouteList,
} from "@/enum/dict/index.js";

import ComboCard from "./combo-card.vue";
import { getStoreComboList, editStoreComboList } from "../api.js";

export default {
  name: "EditCombo",
  components: { ComboCard },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    orderNo: {
      type: String,
      required: false,
      default: null,
    },
    comboId: {
      type: Number,
      required: false,
      default: null,
    },
    comboRoute: {
      type: Number,
      required: false,
      default: EnumComboRoute.ROUTE_1,
    },
    rewardComboNum: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      searchData: {
        comboType: EnumComboType.COMMON.toString(),
        comboRoute: EnumComboRoute.ROUTE_1,
      },
      form: {
        remark: undefined,
        comboId: undefined,
      },
      list: [],
      dict: {
        ComboGradeList,
        EnumComboType,
        CmoboGradeToColorMap,
        ComboTypeForReviewList,
        EnumComboRoute,
        ComboRouteList,
      },
    };
  },
  computed: {
    tabs() {
      if (this.rewardComboNum > 0) {
        return this.dict.ComboTypeForReviewList;
      }

      return [this.dict.ComboTypeForReviewList[0]];
    },
    groupedList() {
      let count = 0;

      return this.dict.ComboGradeList.map((grade) => {
        return {
          list: this.list
            .filter((combo) => {
              return grade.value === combo.comboGrade;
            })
            .map((combo) => {
              combo.index = ++count;
              return combo;
            }),
          grade,
        };
      }).filter((grade) => {
        return grade.list.length > 0;
      });
    },
  },
  watch: {
    "searchData.comboType"() {
      this.getList();
    },
    "searchData.comboRoute"() {
      this.getList();
    },
  },
  methods: {
    onCheckedChange(id) {
      this.form.comboId = id;
    },
    groupBy(grade) {
      return this.list.filter((combo) => {
        return combo.comboGrade === grade;
      });
    },
    afterOpen() {
      this.$set(this, "searchData", this.$options.data().searchData);
      this.$set(this, "form", this.$options.data().form);

      this.form.comboId = this.comboId;
      this.searchData.comboRoute = this.comboRoute;

      this.getList();
    },
    async getList() {
      const res = await getStoreComboList({
        orderNo: this.orderNo,
        comboRoute:
          this.searchData.comboType ===
          this.dict.EnumComboType.COMMON.toString()
            ? this.searchData.comboRoute
            : undefined,
        comboTab: this.searchData.comboType,
      });

      this.list = res;

      const existComboId = this.list.find(
        (item) => item.comboId === this.comboId,
      );

      if (!existComboId) {
        this.form.comboId = null;
      } else {
        this.form.comboId = this.comboId;
      }
    },
    onClose() {
      this.$emit("update:visible", false);
    },
    async onSubmit() {
      const combo = this.list.find(
        (item) => item.comboId === this.form.comboId,
      );

      if (!combo) {
        this.$message.error("请选择方案");

        return;
      }

      await this.$confirm(
        `是否确认 <strong>${combo.comboName}</strong> 为新方案？`,
        {
          dangerouslyUseHTMLString: true,
        },
      );

      await editStoreComboList({
        orderNo: this.orderNo,
        comboTab: this.searchData.comboType,
        comboId: this.form.comboId,
        remark:
          this.searchData.comboType == EnumComboType.CUSTOM
            ? this.form.remark
            : null,
      });

      this.$message({
        type: "success",
        message: "操作成功",
      });

      this.onClose();

      localStorage.setItem("is_update_period", "true");

      this.$emit("refresh");
    },
  },
};
</script>
<style scoped lang="scss">
.combo-dialog {
  /deep/ {
    .el-dialog {
      margin-bottom: 0px !important;
    }
  }
}

.header-search {
  .search-form-item {
    width: 180px;

    /deep/ {
      input {
        text-align: left;
      }
    }
  }
}

.combo-body {
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 5vh - 140px);
  .grade-list {
    display: flex;
    flex-direction: column;
    gap: 30px;
    overflow-y: auto;
    flex: 1;
    max-height: 100%;
  }
}

.grade-group {
  display: flex;
  align-items: center;
  gap: 10px;

  .grade-name {
    font-size: 16px;
    font-weight: bold;
  }

  .combo-list {
    position: relative;
    flex: 1;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;

    .divider {
      position: absolute;
      top: -15px;
      width: 100%;
      border-top: 1px dashed #000;
    }
  }
}

.empty-grade-group {
  width: 100px;
  height: 34px;
  border-radius: 6px;
}
.dialog-save {
  position: absolute;
  right: 20px;
  top: 65px;
}

.search-form {
  border-bottom: 1px solid #ccc;
  margin-bottom: 15px;
  padding-bottom: 15px;
}
</style>
