import { render, staticRenderFns } from "./combo-card.vue?vue&type=template&id=fc33d594&scoped=true"
import script from "./combo-card.vue?vue&type=script&lang=js"
export * from "./combo-card.vue?vue&type=script&lang=js"
import style0 from "./combo-card.vue?vue&type=style&index=0&id=fc33d594&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fc33d594",
  null
  
)

export default component.exports